/* eslint-disable */
require('./account_circle')
require('./add_circle_outline')
require('./add')
require('./admin_panel_settings')
require('./alert')
require('./apps')
require('./arrow_back')
require('./assignment_turned_in')
require('./assignment')
require('./attach_file')
require('./calendar')
require('./call_end')
require('./call')
require('./chart-bubble')
require('./chart')
require('./check_circle_outline')
require('./check')
require('./chevron_left_double')
require('./chevron_left')
require('./chevron_right_double')
require('./chevron_right')
require('./clear')
require('./delete_forever')
require('./description')
require('./diabetes')
require('./edit')
require('./email')
require('./event_available')
require('./expand_more')
require('./favorite')
require('./filter')
require('./fitness_center')
require('./forum')
require('./gauge')
require('./healing')
require('./highlight_off')
require('./history')
require('./human-male-height')
require('./image')
require('./info')
require('./insert_chart_outlined')
require('./institution')
require('./label')
require('./link')
require('./list')
require('./local_hospital')
require('./local_pharmacy')
require('./location_on')
require('./lock_open')
require('./lock')
require('./loyalty')
require('./lungs')
require('./medical_services')
require('./medical-specialty')
require('./message')
require('./mic_off')
require('./mic')
require('./more_horiz')
require('./note_add')
require('./notifications_active')
require('./notifications_off')
require('./pending_actions')
require('./people')
require('./perm_contact_calendar')
require('./person')
require('./phone_iphone')
require('./phonelink_erase')
require('./phonelink_ring')
require('./phonelink')
require('./playlist_add')
require('./pulse')
require('./refresh')
require('./reorder')
require('./reply')
require('./search')
require('./sentiment_satisfied_alt')
require('./settings')
require('./shoe-print')
require('./smartphone')
require('./thermometer')
require('./today')
require('./videocam_off')
require('./videocam')
require('./videogame')
require('./visibility')
require('./weight')
